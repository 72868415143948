<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `Sobre nosotros` : `About Us` }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Llevando confiabilidad al mundo de las Loterías de México`
            : `Bringing reliability to the world of Mexico Lotteries`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Loterías de México es presentado por un pequeño y dedicado equipo de escritores profesionales, expertos en lotería y talentos locales de Mexico. Habiendo pasado un tiempo viviendo en Mexico, el Equipo reconoció el caos que rodea a las noticias, noticias falsas, rumores y rumores. Esto no es más evidente que en el azaroso sector de las loterías, tanto en línea como en la calle.`
            : `Mexico Lotteries is brought to you by a small, dedicated team of professional writers, lottery experts and local Mexico talent. Having all spent time living in Mexico, The Team recognised the chaos that surrounds news, false news, rumour and hearsay. This is no more evident than in the haphazard sector of lotteries, both online and in the street.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Las Loterías de México parecen ir y venir a diario, los números ganadores no se anuncian hasta una semana después del sorteo y no existía una fuente confiable para la difusión de los resultados confiables de la lotería. Las estafas abundan.`
            : `Mexico Lotteries seem to come and go on a daily basis, winning numbers aren't announced until a week or so after the drawing and no reliable source existed for the dissemination of reliable lottery results. Scams are rife.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Y así nació México Loterías con la misión de "Brindar confiabilidad al Mundo de las Loterías de México", un sitio web en el que la gente pudiera confiar para informar los números ganadores correctos y los resultados de manera oportuna.`
            : `And so Mexico Lotteries was born with the mission to "Bring reliability to the World of Mexico Lotteries" - a website that people could trust to report the correct winning numbers and results in a timely manner.`
        }}
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    document.title = 'About | Mexico Lotteries';
  },
};
</script>

<style>
.faqBox {
  margin-bottom: 20px;
}

@media all and (max-width: 768px) {
  .faqBox {
    padding: 20px;
  }

  .faqBox div {
    margin-bottom: 0;
  }

  .faqBox p {
    margin-bottom: 0;
  }
}
</style>
