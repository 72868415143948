<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `Contacta con nosotros` : `Contact Us` }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Ya sea que tenga una pregunta sobre las loterías de Mexico, los resultados, los premios o cualquier otra cosa, nuestro equipo está listo para responder a todas sus preguntas. Envíenos un correo electrónico a la siguiente dirección:`
            : `Whether you have a question about Mexico Lotteries, results, prizes, or anything else, our team is here ready to answer all of your questions. Please send an email to us at the address below:`
        }}
      </p>

      <div id="spacer"></div>

      <div class="faqBox">
        <b>support@mexicolotteries.com</b>
        <div id="spacer" class="desktopOnly"></div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    document.title = 'Contact Us | Mexico Lotteries';
  },
};
</script>

<style>
@media all and (max-width: 768px) {
  .faqBox {
    padding: 20px;
  }

  .faqBox div {
    margin-bottom: 0;
  }

  .faqBox p {
    margin-bottom: 0;
  }
}
</style>
