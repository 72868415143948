<template>
  <div id="Footer">
    <div id="footerWrap">
      <div id="left-wrap">
        <div class="flexMob">
          <img src="../assets/google-play.png" alt="Play Store" />
          <img src="../assets/app-store.png" alt="App Store" />
        </div>

        <div id="Footer2">
          <div class="centerFoot">
            &copy;2001
            {{
              this.chosenLang === 'bn'
                ? `Loterías de México Todos los derechos reservados.`
                : `Mexico Lotteries All Right Reserved.`
            }}
          </div>
          <div class="centerFoot">
            <a href="/contact-us">{{ this.chosenLang === 'bn' ? `Contacta con nosotros` : 'Contact Us' }}</a>
            |
            <a href="/about-us">{{ this.chosenLang === 'bn' ? `Sobre nosotros` : `About Us` }}</a>
            |
            <a href="/privacy-policy">{{ this.chosenLang === 'bn' ? `Política de privacidad` : `Privacy Policy` }}</a>
          </div>
        </div>
      </div>

      <div id="Footer3">
        <div class="rightFootText">
          {{
            this.chosenLang === 'bn'
              ? `Suscríbase para obtener las últimas noticias.`
              : `Subscribe to get the latest news.`
          }}
        </div>

        <div class="rightFoot">
          <input type="text" :placeholder="placeholder" />
          <div class="btn btn-primary">{{ this.chosenLang === 'bn' ? `Suscribir` : `Subscribe` }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    placeholder() {
      if (!localStorage.getItem('chosenLang') || localStorage.getItem('chosenLang') === 'bn')
        return `Tu mejor correo electrónico aquí ...`;

      return `Your best email here...`;
    },
  },
};
</script>

<style>
#Footer {
  background: linear-gradient(#4b484d, var(--dark));
  padding: 60px;
  padding-bottom: 80px;
  margin-top: 40px;
}

#footerWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1366px;
  margin: auto;
}

#left-wrap {
  display: flex;
  align-items: center;
}

#left-wrap img {
  margin-right: 10px;
}

#Footer2 {
  color: white;
  margin-left: 10px;
  height: fit-content;
  letter-spacing: 0.0125em;
}

.centerFoot {
  line-height: 150%;
}

.rightFootText {
  font-size: 1.4em;
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
}

.rightFoot {
  display: flex;
}

.rightFoot input {
  width: 250px;
  margin-right: 10px;
}

#Footer a {
  color: var(--light-accent);
}

#Footer a:hover {
  color: white;
  text-decoration: underline;
}

#Footer a:active {
  color: var(--light-accent);
}

@media all and (max-width: 768px) {
  #Footer {
    padding: 20px;
    padding-bottom: 40px;
  }

  #footerWrap {
    display: block;
  }

  #left-wrap {
    display: block;
  }

  #left-wrap img {
    margin-bottom: 10px;
  }

  #Footer2 {
    margin-top: 10px;
  }

  #Footer3 {
    margin-top: 15px;
    padding: 10px;
  }

  .flexMob {
    display: flex;
    padding: 10px;
  }

  .flexMob img {
    width: 48%;
    height: 100%;
  }

  .centerFoot {
    margin-bottom: 5px;
  }

  .rightFootText {
    margin-bottom: 15px;
    line-height: 120%;
    font-size: 1.7em;
  }
}
</style>
