<template>
  <div id="HotCold">
    <div class="HotColdContainer Hot">
      <div class="HotColdHead Hot">
        <img src="../assets/hot-icon.png" alt="Hot Icon" />
        {{ this.chosenLang === 'bn' ? `Número caliente` : `Hot Number` }}
      </div>

      <div class="HotColdBody">
        {{
          this.chosenLang === 'bn'
            ? `Hot Number es un número que se muestra más durante nuestro giro. Es posible que desee considerar estos números en sus apuestas.`
            : `Hot Number is a number that shows the most during our spin. You might want to consider these numbers on your betting.`
        }}
      </div>

      <div class="ballContainer">
        <div class="ball red">{{ hotColdArr[0] }}</div>
        <div class="ball red">{{ hotColdArr[1] }}</div>
        <div class="ball red">{{ hotColdArr[2] }}</div>
      </div>
    </div>

    <div class="HotColdContainer Blue">
      <div class="HotColdHead Cold">
        <img src="../assets/cold-icon.png" alt="Cold Icon" />
        {{ this.chosenLang === 'bn' ? `Número frío` : `Cold Number` }}
      </div>
      <div class="HotColdBody">
        {{
          this.chosenLang === 'bn'
            ? `Cold Number es un número que muestra menos durante nuestro giro. Es posible que no desee utilizar estos números en sus apuestas`
            : `Cold Number is a number that shows the least during our spin. You might not want to use these numbers on your betting.`
        }}
      </div>

      <div class="ballContainer">
        <div class="ball blue">{{ hotColdArr[3] }}</div>
        <div class="ball blue">{{ hotColdArr[4] }}</div>
        <div class="ball blue">{{ hotColdArr[5] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    fetch('/api/hot-cold')
      .then(response => {
        response.json().then(res => {
          this.hotColdArr = res;
        });
      })
      .catch(response => {
        response.json().then(res => {
          alert(res.message);
        });
      });
  },
  data() {
    return {
      hotColdArr: [],
    };
  },
};
</script>

<style>
#HotCold {
  display: flex;
  margin-top: 70px;
  justify-content: space-between;
}

.HotColdHead {
  padding: 10px 25px;
  border-radius: 5px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  color: white;
  font-weight: bold;
  text-align: center;
  position: relative;
  top: -20px;
  height: 30px;
  display: flex;
  align-items: center;
  font-size: 1.3em;
  width: fit-content;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}

.HotColdHead img {
  width: 20px;
  margin-right: 15px;
}

.Hot {
  background-color: #e13c47;
}

.HotColdContainer.Hot {
  background: linear-gradient(white, #ffb4b0);
}

.HotColdContainer.Blue {
  background: linear-gradient(white, #dae6ea);
}

.Cold {
  background-color: #39aee3;
}

.HotColdContainer {
  border-radius: 5px;
  padding: 0 20px 20px 20px;
  width: 45.25%;
  border: solid 1px var(--light);
}

.HotColdBody {
  line-height: 125%;
  text-align: center;
}

.ballContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 175px;
  margin: auto;
  margin-top: 15px;
}

@media all and (max-width: 768px) {
  #HotCold {
    display: block;
  }

  .HotColdContainer {
    width: 85%;
    margin: auto;
    margin-bottom: 60px;
  }

  .HotColdContainer.Blue {
    margin-bottom: 0;
  }

  .HotColdBody {
    line-height: 150%;
  }
}
</style>
