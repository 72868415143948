import Vue from 'vue';
import './reset.css';
import './root.css';
import VueRouter from 'vue-router';
import routes from './routes';
import App from './pages/App';
import moment from 'moment';
import jwtDecode from 'jwt-decode';

Vue.config.productionTip = false;
Vue.use(VueRouter);

const vueRouter = new VueRouter({
  routes: routes,
  mode: 'history',
});

vueRouter.beforeEach((to, from, next) => {
  // Kalau requiresAuth
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // Ada token ga? Kalau gaada langsung aja ke login
    if (localStorage.getItem('accessToken') === null) next({ path: '/login' });

    // Kalau ada token, cek dulu udah expired belom
    const decoded = jwtDecode(localStorage.getItem('accessToken'));
    const expToken = moment(new Date(decoded.exp * 1000));
    const now = moment(new Date());

    // Kalau expired redirect ke login
    if (now.diff(expToken, 'days') > 0) {
      alert(`Sesi Anda telah berakhir. Silahkan login kembali.`);
      next({ path: '/login' });
    }

    // Kalau aman-aman aja lanjut gan
    next();
  }

  next();
});

export const EventBus = new Vue();
Vue.component('EventBus', EventBus);

Vue.mixin({
  beforeCreate() {
    if (!localStorage.getItem('chosenLang')) localStorage.setItem('chosenLang', 'bn');
  },

  created() {
    this.setLang();
    EventBus.$on('set-lang', this.setLang);
  },

  methods: {
    setLang() {
      this.chosenLang = localStorage.getItem('chosenLang');
    },
  },

  data() {
    return {
      chosenLang: '',
    };
  },

  filters: {
    tail(resultNo) {
      if (parseInt(resultNo) === 1) return 'st';
      else if (parseInt(resultNo) === 2) return 'nd';
      else if (parseInt(resultNo) === 3) return 'rd';
      else return 'th';
    },
  },
});

new Vue({
  el: '#app',
  render: h => h(App),
  router: vueRouter,
});
