<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `Lotería legal` : `Legal Lottery` }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Las loterías legales y las leyes de lotería varían según el estado dentro de Mexico. Actualmente hay 13 estados que permiten los juegos de lotería, mientras que el resto los ha prohibido. No existe una prohibición nacional, pero tras una decisión de 2015 de la Corte Suprema del Estado de Mexico, los estados individuales toman la decisión de permitir o no las loterías. Los estados tienen la autoridad para controlar los juegos de lotería en su jurisdicción particular (no internacionalmente) y garantizar que todos los sorteos de lotería que se realicen se realicen de manera justa y cumplan con las reglas y regulaciones de ese juego en particular.`
            : `Legal lotteries and lottery laws vary by state within Mexico. There are currently 13 states that allow lottery games, while the rest have banned them. There is no national ban, but following a 2015 decision by the State Supreme Court of Mexico, individual states take the decision whether to allow lotteries or not. States have the authority to control lottery games in their particular jurisdiction (not internationally) and ensure that all lottery draws that take place are run fairly and comply with the rules and regulations of that particular game.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Las Loterías del Estado de Kerala es la lotería administrada por el gobierno más antigua de Mexico y ha estado en funcionamiento desde 1967. Ofrece juegos semanales y Loterías Bumper que se llevan a cabo seis veces al año. La Lotería de Punjab lleva a cabo esquemas de Lotería Bumper durante todo el año.`
            : `Kerala State Lotteries is the oldest government-run lottery in Mexico, and has been in operation since 1967. It offers weekly games as well as Bumper Lotteries held six times a year. The Punjab Lottery holds regular Bumper Lottery schemes throughout the year.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `El juego de lotería estatal más grande de Mexico es la Lotería Playwin, administrada por el gobierno de Sikkim y disponible en varios estados. Para jugar a esta lotería debes tener 18 años o más. La Lotería Estatal de Sikkim también realiza sorteos extraordinarios durante todo el año.`
            : `The biggest state lottery game in Mexico is the Playwin Lottery, which is run by the Sikkim government and available in a number of states. To play this lottery you must be 18 years old or over. The Sikkim State Lottery also runs Bumper draws throughout the year.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>{{ this.chosenLang === 'bn' ? `Lotería internacional` : `International Lottery` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Loterías internacionales como Powerball, Mega Millions y Euromillones ofrecen grandes premios, por encima del nivel disponible en los juegos de México; consulte la comparación de loterías para obtener más detalles. Existe una forma segura y confiable de jugar loterías internacionales en la que cualquier persona en México puede participar: un servicio de conserjería de lotería. Los jugadores pueden comprar boletos en línea de manera segura desde México para las principales loterías del mundo sin tener que visitar los EE. UU. O Europa. Debido a que estos sorteos se llevan a cabo fuera de México, comprar boletos de lotería en línea es legal en todo el país, independientemente de las leyes de juego en cualquier estado en particular.`
            : `International lottiers like Powerball, Mega Millions, and EuroMillions offer huge jackpots, above the level available on Mexico games - see the lottery comparison for more details. There is a safe, reliable way to play international lotteries that anyone in Mexico can take part in - a lottery concierge service. Players can safely purchase tickets online from Mexico for the top world lotteries without having to visit the USA or Europe. Because these draws are held outside Mexico, buying lottery tickets online is legal throughout the country, regardless of the gambling laws in any particular state.`
        }}
      </p>

      <div id="spacer"></div>

      <div class="faqBox">
        <div>
          <b>{{
            this.chosenLang === 'bn'
              ? `1. ¿Puedo jugar loterías internacionales de México?`
              : `1. Can I play international lotteries from Mexico?`
          }}</b>
        </div>

        <p>
          {{
            this.chosenLang === 'bn'
              ? `Sí, puede jugar a loterías internacionales como Powerball y Mega Millions en línea. Para leer más sobre esta instalación, visite la página Cómo jugar.`
              : `Yes, you can play international lotteries such as Powerball and Mega Millions online. To read more about this facility, visit the How to Play page.`
          }}
        </p>

        <div>
          <b>{{
            this.chosenLang === 'bn'
              ? `2. ¿Cómo puedo reclamar las ganancias si gano una lotería internacional de México?`
              : `2. How can I claim the winnings if I win an international lottery from Mexico?`
          }}</b>
        </div>

        <p>
          {{
            this.chosenLang === 'bn'
              ? `Si gana un premio, recibirá un mensaje de texto o correo electrónico informándole de su ganancia. Los premios se ingresan automáticamente en su cuenta en línea poco después de que se haya realizado el sorteo y puede optar por retirar sus ganancias o utilizarlas para financiar futuras compras de boletos.`
              : `If you win a prize, you will receive a text message or email informing you of your win. Prizes are automatically paid into your online account soon after the draw has been made and you can choose to withdraw your winnings or use them to fund future ticket purchases.`
          }}
        </p>

        <div>
          <b>{{
            this.chosenLang === 'bn'
              ? `3. ¿Puedo transferir el dinero que he ganado a mi cuenta bancaria en México?`
              : `3. Can I transfer the money I have won to my bank account in Mexico?`
          }}</b>
        </div>

        <p>
          {{
            this.chosenLang === 'bn'
              ? `Si. Cuando configure su cuenta en línea, se le pedirá que elija uno de los muchos métodos disponibles para financiar su cuenta. Puede utilizar una variedad de métodos para retirar sus ganancias, incluidos Visa, Mastercard y Neteller. Alternativamente, las ganancias en su cuenta de jugador se pueden usar para comprar más boletos para sorteos futuros.`
              : `Yes. When you set up your online account, you will be required to choose one of the many methods available for funding your account. You can use a variety of methods to withdraw your winnings including Visa, Mastercard and Neteller. Alternatively, the winnings in your player account can be used to buy more tickets for future draws.`
          }}
        </p>

        <div>
          <b>{{
            this.chosenLang === 'bn'
              ? `4. ¿El servicio de conserjería de boletos de lotería deducirá una proporción de mis ganancias o tomará una comisión?`
              : `4. Will the lottery ticket concierge service deduct a proportion of my winnings or take a commission?`
          }}</b>
        </div>

        <p>
          {{
            this.chosenLang === 'bn'
              ? `No. Las ganancias se pagan en su totalidad al jugador, aunque debe verificar si su premio lo hace responsable del impuesto sobre la renta en México.`
              : `No. Winnings are paid in full to the player, although you should check if your prize makes you liable for income tax in Mexico.`
          }}
        </p>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    document.title = 'Help | Mexico Lotteries';
  },
};
</script>

<style>
.faqBox {
  background-color: var(--light);
  padding: 40px 40px 10px 40px;
}

.faqBox div {
  margin-bottom: 10px;
}

.faqBox p {
  margin-bottom: 30px;
}

@media all and (max-width: 768px) {
  .faqBox {
    padding: 20px;
  }

  .faqBox div {
    margin-bottom: 0;
  }

  .faqBox p {
    margin-bottom: 0;
  }
}
</style>
