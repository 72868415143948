<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `Cómo jugar` : `How To Play` }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `¿Alguna vez has deseado poder jugar una gran lotería internacional como Powerball, Mega Millions y EuroMillions? Ahora puedes jugar a estos increíbles juegos online desde México. Las loterías estadounidenses no están sujetas a las restricciones de juego de México, por lo que puede jugar desde cualquier lugar del país en su computadora o teléfono. Tendrá la oportunidad de ganar premios mayores que son mucho más grandes que los premios mayores de la lotería mexicana.`
            : `Ever wished you could play a big international lottery like Powerball, Mega Millions, and EuroMillions? Now you can play these incredible games online from Mexico. American lotteries are not subject to Mexico gambling restrictions, so you can play from anywhere in the country on your computer or phone. You'll have the chance to win jackpots that are much larger than the Mexican lottery top prizes.`
        }}
      </p>

      <div id="spacer"></div>
      <img src="../assets/how-to-play.jpg" alt="How To Play" class="desktopOnly" />
      <div id="spacer" class="desktopOnly"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Jugar a la lotería en línea es simple, así que no se preocupe si no ha comprado un boleto en línea o no ha utilizado un servicio de conserjería de lotería antes. Simplemente siga estos pasos para comenzar:`
            : `Playing the lottery online is simple, so don't worry if you haven't bought a ticket online or used a lottery concierge service before. Just follow these steps to get started:`
        }}
      </p>

      <div id="spacer"></div>

      <div class="faqBox">
        <ul>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `Haga clic en cualquiera de los banners o botones de Jugar ahora en mexicolotteries.com.`
                : `Click on any of the banners or Play Now buttons on mexicolotteries.com.`
            }}
          </li>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `Elige la lotería a la que deseas jugar`
                : `Choose the lottery you wish to play`
            }}
          </li>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `Seleccione sus números manualmente usando la cuadrícula de selección de números o genere un conjunto aleatorio de números usando el botón Selección rápida.`
                : `Select your numbers manually by using the number selector grid or generate a random set of numbers using the Quick Pick button.`
            }}
          </li>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `Haga clic en el botón Continuar para confirmar su compra.`
                : `Click the Continue button to confirm your purchase.`
            }}
          </li>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `Ingrese sus datos para abrir su cuenta o registre una nueva cuenta y complete su compra.`
                : `Enter your details to open your account, or register a new account, and complete your purchase.`
            }}
          </li>
          <li>
            {{
              this.chosenLang === 'bn'
                ? `Después de recibir su pedido, puede ver sus números en su cuenta en línea.`
                : `After receiving your order, you can view your numbers in your online account.`
            }}
          </li>
        </ul>
      </div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Si gana un premio, recibirá un mensaje de texto / SMS o correo electrónico a la dirección de correo electrónico proporcionada cuando registró su cuenta. También puede ver los resultados de cada lotería en las páginas de la lotería poco después de que se haya realizado cada sorteo.`
            : `If you win a prize, you'll receive a text/SMS or email to the email address provided when you registered your account. You can also view the results for each lottery on the lottery pages shortly after each draw has taken place.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Las ganancias se pagan directamente en su cuenta en línea. Las ganancias del premio se pueden retirar o utilizar para futuras compras de boletos.`
            : `Winnings are paid directly into your online account. The prize winnings can then be withdrawn or used for future ticket purchases.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Vea las historias de una mujer de Panamá que ganó $ 30 millones en Florida Lotto y un hombre iraquí que ganó un premio mayor de Oregon Megabucks de $ 6,4 millones, para descubrir lo fácil que es ganar un premio de lotería internacional para usted.`
            : `Check out the stories of a woman from Panama who won $30 million on Florida Lotto, and an Iraqi man who won an Oregon Megabucks jackpot of $6.4 million, to find out how easy it is to win an international lottery prize for yourself.`
        }}
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    document.title = 'How To Play | Mexico Lotteries';
  },
};
</script>

<style>
.faqBox {
  margin-bottom: 20px;
}

@media all and (max-width: 768px) {
  .faqBox {
    padding: 20px;
  }

  .faqBox div {
    margin-bottom: 0;
  }

  .faqBox p {
    margin-bottom: 0;
  }
}
</style>
