import Home from './pages/Home';
import Admin from './pages/Admin';
import Login from './pages/Login';
import HowToPlay from './pages/HowToPlay';
import Help from './pages/Help';
import Results from './pages/Results';
import Contact from './pages/Contact';
import About from './pages/About';
import Privacy from './pages/Privacy';
import NotFound from './pages/NotFound';

const routes = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/admin',
    component: Admin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/how-to-play',
    component: HowToPlay,
  },
  {
    path: '/help',
    component: Help,
  },
  {
    path: '/results',
    component: Results,
  },
  {
    path: '/contact-us',
    component: Contact,
  },
  {
    path: '/about-us',
    component: About,
  },
  {
    path: '/privacy-policy',
    component: Privacy,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '*',
    component: NotFound,
  },
];

export default routes;
