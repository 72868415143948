<template>
  <div id="RunningText">
    <div id="RunningTextWrap">
      {{
        this.chosenLang === 'bn'
          ? `El resultado se actualizará todos los días. Esté atento y apueste responsablemente.`
          : `The result will be updated everyday. Stay tune and gamble responsibly.`
      }}
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
#RunningText {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 8px;
  overflow: hidden;
  max-height: 32px;
  box-sizing: border-box;
}

#RunningTextWrap {
  max-height: 17px;
  overflow: hidden;
  animation-name: running;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes running {
  0% {
    margin-left: -30%;
  }
  100% {
    margin-left: 100%;
  }
}
</style>
