<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `Sobre nosotros` : `About Us` }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Su privacidad es extremadamente importante para las Loterías de México. La empresa y el sitio tienen políticas estrictas para garantizar que cualquier información personal que envíe a las Loterías de México se recopile y almacene de manera segura y se utilice solo cuando sea razonablemente necesario para el funcionamiento del sitio web.`
            : `Your privacy is extremely important to Mexico Lotteries. The company and site have strict policies in place to ensure that any personal information you submit to Mexico Lotteries is collected and stored securely and is used only as reasonably necessary for the operation of the website.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Esta página establece qué tipo de datos recopilamos cuando navega por el sitio, cómo los utilizan las Loterías de México y cómo se mantienen seguros.`
            : `This page sets out what type of data we collect when you browse the site, how Mexico Lotteries use it and how it is kept secure.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Y así nació México Loterías con la misión de "Brindar confiabilidad al mundo de la Lotería Mexicana", un sitio web en el que la gente puede confiar para informar los números ganadores correctos y los resultados de manera oportuna.`
            : `And so Mexico Lotteries was born with the mission to "Bring reliability to the World of Mexican Lotto" - a website that people could trust to report the correct winning numbers and results in a timely manner.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>{{ this.chosenLang === 'bn' ? `¿Qué datos recopilamos?` : `What data do we collect?` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Podemos recopilar los siguientes tipos de información cuando utiliza este sitio web:`
            : `We may collect the following kinds of information when you use this website:`
        }}
      </p>

      <div id="spacer"></div>

      <ul>
        <li>
          {{
            this.chosenLang === 'bn'
              ? `Información sobre cómo utiliza este sitio web y el dispositivo que utiliza para navegar por él. Esto incluye, entre otros, el tipo y la versión de su navegador, el sistema operativo, las páginas que visitó, la duración de su visita y su dirección IP.`
              : `Information about how you use this website and the device you use to browse it. This includes, but is not limited to, your browser type and version, operating system, which pages you visited, the length of your visit and your IP address.`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `Cualquier otra información que elija enviarnos, para cualquier propósito.`
              : `Any other information you choose to send to us, for any purposes.`
          }}
        </li>
      </ul>

      <h2>{{ this.chosenLang === 'bn' ? `Como usamos sus datos` : `How we use your data` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `La información personal enviada a Loterías de México a través de este sitio web se puede utilizar para los fines que se describen a continuación:`
            : `Personal information submitted to Mexico Lotteries via this website may be used for the purposes described below:`
        }}
      </p>

      <div id="spacer"></div>

      <ul>
        <li>{{ this.chosenlang === 'bn' ? `Administración del sitio web` : `Website administration` }}</li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `Mejorar y personalizar el sitio web de acuerdo con sus intereses.`
              : `To improve and personalise the website according to your interests`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `Para enviarle comunicaciones generales o de marketing, así como notificaciones por correo electrónico, pero solo si ha optado por recibirlas.`
              : `To send you general or marketing communications, as well as email notifications but onlyif you have opted in to receive them`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `Para enviarle comunicaciones de marketing relacionadas con nuestro negocio o los negocios de terceros cuidadosamente seleccionados. Solo enviaremos esta información si ha aceptado específicamente recibirla. Puede optar por no participar en cualquier momento poniéndose en contacto con nosotros`
              : `To send you marketing communications relating to our business or the businesses of carefully selected third-parties. We will only send this information if you have specifically agreed to receive it. You can opt out at any time by contacting us`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `Para atender consultas o quejas enviadas por usted a este sitio web.`
              : `To handle queries or complaints sent by you to this website`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `Para mantener la seguridad del sitio web y ayudar a prevenir el fraude`
              : `To maintain website security and help prevent fraud`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `Para habilitar el uso de los servicios del sitio web`
              : `To enable the use of website services`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `Para asegurarse de que cumple con los términos de este sitio web.`
              : `To ensure you are abiding by the terms of this website.`
          }}
        </li>
      </ul>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Las Loterías de México solo compartirán su información con terceros con fines de marketing directo si ha optado por el servicio. Puedes darte de baja en cualquier momento.`
            : `Mexico Lotteries will only share your information with third parties for direct marketing purposes if you have opted in to the service. You can unsubscribe at any time.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>
        {{
          this.chosenLang === 'bn' ? `Divulgación de su información personal` : `Disclosing your personal information`
        }}
      </h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Las Loterías de México pueden divulgar su información personal a cualquiera de nuestros empleados, agentes, proveedores o subcontratistas según sea necesario para los fines establecidos en esta política de privacidad. También podemos divulgar su información personal a cualquier miembro de nuestro grupo de empresas (incluidas las empresas matrices, nuestra sociedad matriz y cualquier subsidiaria) según sea necesario para los fines establecidos en esta política de privacidad.`
            : `Mexico Lotteries may disclose your personal information to any of our employees, agents, suppliers, or subcontractors as necessary for the purposes set out in this privacy policy. We may also disclose your personal information to any member of our group of companies (including parent companies, our holding company and any subsidiaries) as necessary for the purposes set out in this privacy policy.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Además, podemos divulgar su información personal.:`
            : `Furthermore, we may disclose your personal information:`
        }}
      </p>

      <div id="spacer"></div>

      <ul>
        <li>{{ this.chosenLang === 'bn' ? `Si lo requiere la ley` : `If required by law` }}</li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `Para establecer, ejercer o defender nuestros derechos legales, incluso con fines de prevención del fraude.`
              : `To establish, exercise or defend our legal rights, including for fraud prevention purposes`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `A compradores o posibles compradores de cualquier negocio o activo que estemos (o estamos considerando) vendiendo`
              : `To purchasers or prospective purchasers of any business or asset we are (or are considering) selling`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `En relación con cualquier procedimiento legal, ya sea prospectivo o en curso`
              : `In connection with any legal proceedings, whether prospective or ongoing`
          }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `A cualquier persona que creamos razonablemente que puede solicitar a un tribunal o autoridad competente la divulgación de esa información personal cuando, en nuestra opinión razonable, es probable que esa autoridad nos ordene divulgar esa información.`
              : `To any person who we reasonably believe may apply to a court or competent authority for the disclosure of that personal information where, in our reasonable opinion, that authority would be likely to order us to disclose that information`
          }}
        </li>
      </ul>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Loterías de México no divulgará su información personal a terceros, con la excepción de los casos descritos en esta política de privacidad.`
            : `Mexico Lotteries will not disclose your personal information to third parties, with the exception of those instances described in this privacy policy.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>
        {{
          this.chosenLang === 'bn' ? `Seguridad de su información personal` : `Security of your personal information`
        }}
      </h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `La seguridad de los datos es muy importante para mexicolotteries.com y tomamos todas las medidas razonables para evitar la pérdida, mal uso o alteración de su información personal. Todos los datos personales que proporcione a mexicolotteries.com se almacenan en nuestros servidores seguros y todas las transacciones electrónicas realizadas a través de este sitio web están encriptadas.`
            : `Data security is very important to mexicolotteries.com and we take all reasonable steps to prevent the loss, misuse or alteration of your personal information. Any personal data you provide to mexicolotteries.com is stored on our secure servers and all electronic transactions completed through this website are encrypted.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Al utilizar este sitio web y aceptar estos términos, reconoce que la transmisión de información a través de Internet es potencialmente insegura y que mexicolotteries.com no puede garantizar la seguridad de los datos enviados a través de Internet.`
            : `By using this website and accepting these terms, you acknowledge that the transmission of information over the internet is potentially insecure and that mexicolotteries.com cannot guarantee the security of data sent over the internet.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>
        {{ this.chosenLang === 'bn' ? `Transferencias internacionales de datos` : `International data transfers` }}
      </h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `La información personal que recopilamos como se describe en esta política de privacidad puede almacenarse, procesarse o transferirse entre cualquiera de los países en los que operamos para que podamos usarla de acuerdo con los términos establecidos en esta política de privacidad.`
            : `The personal information that we collect as described in this privacy policy may be stored, processed in or transferred between any of the countries in which we operate so that we can use it in accordance with the terms set out in this privacy policy.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Cualquier información personal que proporcione a través de este sitio web puede ser transferida y almacenada en países que no tienen leyes de protección de datos equivalentes a las vigentes en el Espacio Económico Europeo.`
            : `Any personal information that you provide via this website may be transferred to and stored in countries which do not have data protection laws equivalent to those in force in the European Economic Area.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Al aceptar esta política de privacidad, usted reconoce que cualquier información personal que envíe a Mexico Lotteries para su publicación a través de este sitio web puede estar disponible posteriormente para su visualización en Internet y en todo el mundo. Mexico Lotteries no puede evitar el uso o mal uso de dicha información por parte de terceros.`
            : `By agreeing to this privacy policy, you acknowledge that any personal information you submit to Mexico Lotteries for publication via this website may subsequently be available to view on the internet and around the world. Mexico Lotteries cannot prevent the use or misuse of such information by others.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Al aceptar los términos de esta política de privacidad, acepta expresamente la transferencia de sus datos personales como se describe anteriormente.`
            : `By accepting the terms of this privacy policy, you expressly agree to the transfer of your personal data as described above.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>{{ this.chosenLang === 'bn' ? `Modificación de esta política` : `Amending this policy` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Esta política de privacidad puede actualizarse sin previo aviso. Se publicarán nuevas versiones de la política en este sitio web y le recomendamos que vuelva a visitar esta página para mantenerse al día con los cambios. En ocasiones, podemos notificarle de cualquier cambio a esta política de privacidad por correo electrónico.`
            : `This privacy policy may be updated without notice. New versions of the policy will be published on this website and we advise that you revisit this page to keep up with any changes. We may on occasion notify you of any changes to this privacy policy via email.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>{{ this.chosenLang === 'bn' ? `Tus derechos` : `Your rights` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Puede indicarnos que le proporcionemos cualquier dato que tengamos sobre usted en cualquier momento. Dichas solicitudes se cumplirán con las siguientes condiciones:`
            : `You can instruct us to provide you with any data we hold about you at any time. Such requests will be fulfilled subject to the following conditions:`
        }}
      </p>

      <div id="spacer"></div>

      <ul>
        <li>
          {{ this.chosenLang === 'bn' ? `El pago de una tasa administrativa` : `The payment of an administration fee` }}
        </li>

        <li>
          {{
            this.chosenLang === 'bn'
              ? `El suministro de pruebas adecuadas de su identidad. Esto incluye una copia notariada de su pasaporte y una copia original de una factura de servicios públicos con su nombre y dirección. Loterías de México se reservan el derecho de solicitar una prueba adicional de su identidad si es necesario`
              : `The supply of appropriate evidence of your identity. This includes a notarised copy of your passport and an original copy of a utility bill featuring your name and address. Mexico Lotteries reserve the right to request additional proof of your identity if necessary`
          }}
        </li>
      </ul>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Las Loterías de México requieren estos documentos para proteger la identidad de nuestros usuarios y para garantizar que la información personal solo se divulgue a la persona que tiene derecho a recibirla.`
            : `Mexico Lotteries require these documents to protect the identities of our users and to ensure personal information is only disclosed to the person who has the right to receive it.`
        }}
      </p>

      <div id="spacer"></div>

      <h2>{{ this.chosenLang === 'bn' ? `Sitios web de terceros` : `Third-party websites` }}</h2>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `Las Loterías de México pueden contener enlaces a otros sitios web. No respaldamos ni somos responsables del contenido o las prácticas operativas de los sitios de terceros a los que enlazamos.`
            : `Mexico Lotteries may contain links to other websites. We do not endorse and are not responsible for the content or operating practices of the third-party sites we link to.`
        }}
      </p>

      <div id="spacer"></div>

      <p>
        {{
          this.chosenLang === 'bn'
            ? `La política de privacidad establecida en esta página solo se aplica a las Loterías de México y no cubre los sitios web de terceros, que tendrán sus propias políticas de privacidad. Le recomendamos que lea la política de privacidad de cualquier sitio web de terceros, ya que no podemos ser responsables de cómo recopilan o utilizan sus datos.`
            : `The privacy policy set out on this page only pertains to Mexico Lotteries and doesn’t cover any third-party websites, which will have their own privacy policies in place. We advise that you read the privacy policy of any third-party website as we cannot be held responsible for how they collect or use your data.`
        }}
      </p>

      <div id="spacer"></div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },

  mounted() {
    document.title = 'Privacy Policy | Mexico Lotteries';
  },
};
</script>

<style>
.faqBox {
  margin-bottom: 20px;
}

@media all and (max-width: 768px) {
  .faqBox {
    padding: 20px;
  }

  .faqBox div {
    margin-bottom: 0;
  }

  .faqBox p {
    margin-bottom: 0;
  }
}
</style>
