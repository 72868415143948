<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? `Página no encontrada` : `Page Not Found` }}</h1>

      <p>
        <a href="/">{{ this.chosenLang === 'bn' ? `De vuelta a casa` : `Back to Home` }}</a>
      </p>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    Footer,
  },
  mounted() {
    document.title = 'Page Not Found | Mexico Lotteries';
  },
};
</script>

<style>
.faqBox {
  margin-bottom: 20px;
}
</style>
