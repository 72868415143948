<template>
  <div class="resultBox">
    <div class="resultBoxHead" :style="{ color: warnaTulisan }">
      <img src="../assets/star.png" alt="Star Icon" v-if="resultNo == 1" />
      <img src="../assets/star.png" alt="Star Icon" v-if="resultNo == 1" class="desktopOnly" />
      <div v-if="resultNo != 1" />

      <div>
        <span>{{ resultNo }}</span>
        <span>{{ resultNo | tail }}</span>
        <span>&nbsp;{{ this.chosenLang === 'bn' ? `Resultado` : `Result` }}</span>
      </div>

      <img src="../assets/star.png" alt="Star Icon" v-if="resultNo == 1" class="desktopOnly" />
      <img src="../assets/star.png" alt="Star Icon" v-if="resultNo == 1" />
      <div v-if="resultNo != 1" />
    </div>

    <div class="resultBoxBody">
      <div class="resultBoxBodyWrap">
        <h2>{{ this.chosenLang === 'bn' ? `Número de sorteo` : `Draw Number` }}: {{ drawNo }}</h2>
        <p>{{ date | dateFormatter }}</p>

        <div class="resultNumberWrap">
          <div
            class="ball"
            :class="{ yellow: resultNo == 1, green: resultNo == 2, orange: resultNo == 3 }"
            v-for="(res, index) in thisRes"
            :key="index"
          >
            {{ res }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: ['resultNo', 'result', 'date', 'drawNo'],

  created() {
    const resArray = this.result.split('');
    resArray.forEach(number => {
      this.thisRes.push(number);
    });
  },

  data() {
    return {
      thisRes: [],
    };
  },

  filters: {
    dateFormatter(date) {
      if (!localStorage.getItem('chosenLang') || localStorage.getItem('chosenLang') === 'bn') {
        moment.locale('es');
      } else {
        moment.locale('en');
      }

      return moment(date).format('DD MMMM YYYY');
    },
  },
  computed: {
    warnaTulisan() {
      return this.resultNo == 1 ? 'yellow' : 'white';
    },
  },
};
</script>

<style>
.resultBoxHead {
  background-color: var(--primary);
  color: white;
  padding: 15px 25px;
  border-radius: 5px;
  text-align: center;
  width: 30%;
  margin: auto;
  font-weight: bold;
  position: relative;
  z-index: 1;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.2em;
}

.resultBoxHead img {
  width: 30px;
}

.resultBoxBody {
  border: solid 1px var(--light);
  border-radius: 5px;
  position: relative;
  top: -18px;
  z-index: 0;
  padding: 20px;
  padding-top: 38px;
  background: linear-gradient(rgba(255, 255, 255, 0.8), var(--light));
}

.resultNumberWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  margin-bottom: 10px;
  max-width: 225px;
}

.resultBoxBodyWrap {
  max-width: 250px;
  text-align: center;
  margin: auto;
}

.resultBoxBodyWrap h2 {
  font-weight: bold;
  font-size: 1.5em;
  margin-bottom: 5px;
}

.resultBoxBodyWrap p {
  margin-bottom: 15px;
}

@media all and (max-width: 768px) {
  .resultBoxHead {
    width: 70%;
  }

  .desktopOnly {
    display: none;
  }
}
</style>
