<template>
  <div id="konfirmasi">
    <div id="konfirmasiWindow">
      <div id="tulisanKonfirmasi">
        Silahkan dicek kembali apakah angka di bawah ini sudah benar untuk diinput ke dalam result tanggal
        <b>{{ new Date() | readableTime }}</b
        >?
      </div>

      <div id="boxAngka">{{ result }}</div>

      <div id="buttonWrap">
        <div class="btn btn-primary-box" @click="tutup">Salah</div>
        <div class="btn btn-primary" @click="confirm">Benar</div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  methods: {
    tutup() {
      this.$emit('tutup');
    },
    confirm() {
      this.$emit('confirm');
    },
  },
  props: ['result'],
  filters: {
    readableTime(date) {
      moment.locale('id');
      return moment(date).format('Do MMMM YYYY');
    },
  },
};
</script>

<style>
#konfirmasi {
  background-color: rgba(0, 0, 0, 0.3);
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

#konfirmasiWindow {
  background-color: var(--light);
  width: 50%;
  height: 50%;
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  text-align: center;
}

#tulisanKonfirmasi {
  font-size: 1.1em;
  line-height: 125%;
  margin-bottom: 20px;
}

#boxAngka {
  background-color: white;
  font-size: 10em;
  font-weight: bold;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 5px;
  margin-bottom: 20px;
  letter-spacing: 0.2em;
}

#buttonWrap {
  display: flex;
  justify-content: space-between;
}

#buttonWrap .btn {
  flex-grow: 0.49;
}
</style>
