<template>
  <div id="HomePage">
    <Navbar />
    <RunningText />

    <div id="HomeContent">
      <h1>{{ this.chosenLang === 'bn' ? 'Resultado de Loterías de México' : 'Mexico Lotteries Result' }}</h1>

      <p>
        {{
          this.chosenLang === 'bn'
            ? 'Loterías de México es su sitio único para todos los resultados e información de la Lotería de México. Todos los resultados se actualizan tan pronto como se realizan los sorteos de lotería, y poco después se agregan desgloses de premios.'
            : 'Mexico Lotteries is your one-stop site for all Mexico Lottery Results and information. All results are updated as soon as the lottery draws take place, with prize breakdowns added soon afterwards.'
        }}
      </p>

      <div style="height: 30px;"></div>

      <ResultBox
        v-if="this.results.length > 0"
        resultNo="1"
        :date="this.results[0].date"
        :drawNo="this.results[0].drawNo"
        :result="this.results[0].first"
      />

      <div id="result23">
        <div class="result23Wrap">
          <ResultBox
            v-if="this.results.length > 0"
            resultNo="2"
            :date="this.results[0].date"
            :drawNo="this.results[0].drawNo"
            :result="this.results[0].second"
          />
        </div>

        <div class="result23Wrap">
          <ResultBox
            v-if="this.results.length > 0"
            resultNo="3"
            :date="this.results[0].date"
            :drawNo="this.results[0].drawNo"
            :result="this.results[0].third"
          />
        </div>
      </div>

      <JackpotBox v-if="this.results.length > 0" :jackpot="this.results[0].jackpot" :date="this.results[0].date" />

      <HotCold />
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '../components/Navbar';
import RunningText from '../components/RunningText';
import ResultBox from '../components/ResultBox';
import JackpotBox from '../components/JackpotBox';
import HotCold from '../components/HotCold';
import Footer from '../components/Footer';

export default {
  components: {
    Navbar,
    RunningText,
    ResultBox,
    JackpotBox,
    HotCold,
    Footer,
  },

  methods: {
    getResult(limit) {
      fetch(`/api/result?limit=${limit}`)
        .then(response => {
          response.json().then(res => {
            this.results = res;
          });
        })
        .catch(response => {
          response.json().then(res => {
            alert(res.message);
          });
        });
    },
  },
  mounted() {
    this.getResult(1);
    document.title = 'Home | Mexico Lotteries';
  },

  data() {
    return {
      results: [],
    };
  },
};
</script>

<style>
#HomePage {
  background: url('../assets/bg.jpg');
  background-size: cover;
  min-height: 100vh;
}

#HomeContent {
  background-color: white;
  width: 80%;
  max-width: 1152px;
  margin: auto;
  margin-top: 30px;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 5px;
}

#HomeContent h1 {
  border-bottom: dashed 2px var(--light);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

#HomeContent p {
  line-height: 150%;
}

#result23 {
  display: flex;
  justify-content: space-between;
}

.result23Wrap {
  margin-top: 20px;
  flex-grow: 0.48;
}

@media all and (max-width: 768px) {
  #HomeContent {
    width: 90%;
    margin-top: 20px;
    padding: 20px;
  }

  #result23 {
    display: block;
  }

  #HomeContent h1,
  #HomeContent p {
    padding: 10px;
  }

  #HomeContent h1 {
    line-height: 125%;
  }
}
</style>
