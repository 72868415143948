<template>
  <div id="AdminPage">
    <h1>Input Result</h1>

    <div id="inputField">
      <input type="text" placeholder="Input result 4 angka" maxlength="4" v-model="result" :disabled="this.todayDone" />
      <div class="btn btn-primary" @click="validasi">Input</div>
    </div>

    <div id="resultField">
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>First</th>
            <th>Second</th>
            <th>Third</th>
            <th>Draw No.</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(result, index) in results" :key="index">
            <td>{{ result.date }}</td>
            <td>{{ result.first }}</td>
            <td>{{ result.second }}</td>
            <td>{{ result.third }}</td>
            <td>{{ result.drawNo }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <Konfirmasi v-if="showKonfirmasi" @confirm="confirm" @tutup="showKonfirmasi = false" :result="result" />
  </div>
</template>

<script>
import Konfirmasi from '../components/Konfirmasi';
import moment from 'moment';

export default {
  methods: {
    validasi() {
      if (this.todayDone) {
        return alert('Result hari ini sudah diinput.');
      } else if (isNaN(this.result)) {
        return alert('Result harus berupa angka.');
      } else if (this.result.length !== 4) {
        return alert('Result minimal harus 4 angka.');
      }

      this.showKonfirmasi = true;
    },
    inputResult() {
      fetch('/api/result', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          result: this.result,
        }),
      }).then(response => {
        response
          .json()
          .then(res => {
            alert(res.message);

            if (response.ok) {
              this.getResult(10);
              this.result = '';
            }
          })
          .catch(response => {
            response.json().then(res => {
              alert(res.message);
            });
          });
      });
    },
    getResult(limit) {
      fetch(`/api/result?limit=${limit}`)
        .then(response => {
          response.json().then(res => {
            this.results = res;
            this.checkTodayDone(res[0].date);
          });
        })
        .catch(response => {
          response.json().then(res => {
            alert(res.message);
          });
        });
    },
    confirm() {
      this.inputResult();
      this.showKonfirmasi = false;
    },
    checkTodayDone(date) {
      const lastRes = moment(date);
      const now = moment(new Date());
      if (now.diff(lastRes, 'days') === 0) {
        this.todayDone = true;
      }
    },
    mounted() {
      document.title = 'Admin | Mexico Lotteries';
    },
  },
  data() {
    return {
      result: '',
      results: [],
      showKonfirmasi: false,
      todayDone: false,
    };
  },
  mounted() {
    this.getResult(10);
  },
  components: {
    Konfirmasi,
  },
};
</script>

<style>
#AdminPage {
  background: linear-gradient(white, var(--light));
  min-height: 100vh;
  padding: 30px;
  box-sizing: border-box;
}

#inputField {
  display: flex;
  margin-bottom: 20px;
}

#AdminPage h1 {
  margin-bottom: 10px;
}

#inputField input {
  width: 200px;
  margin-right: 10px;
}

table {
  border-radius: 8px;
  overflow: hidden;
}

table thead tr {
  background-color: var(--dark);
  color: white;
}

table thead tr th {
  padding: 15px 30px;
  font-weight: bold;
  text-align: center;
}

table tbody tr td {
  padding: 15px 30px;
  background-color: white;
  text-align: center;
}
</style>
