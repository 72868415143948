<template>
  <div id="LoginPage">
    <div id="LoginWindow">
      <h1>Mexico Pools</h1>

      <input
        type="text"
        placeholder="Email"
        v-model="email"
        v-on:keyup.enter="$event.target.nextElementSibling.focus()"
      />

      <input
        type="password"
        placeholder="Password"
        v-model="password"
        @keydown="checkInput($event)"
        v-on:keyup.enter="login"
      />

      <div class="btn btn-primary" @click="login">Login</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    login() {
      if (this.email === '') {
        alert('Silahkan isi email Anda');
      } else if (this.validEmail(this.email) === false) {
        alert('Email tidak valid.');
      } else if (this.password === '') {
        alert('Silahkan isi password Anda');
      } else {
        fetch('/api/login', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: this.email,
            password: this.password,
          }),
        })
          .then(response => {
            response.json().then(res => {
              alert(res.message);

              if (response.ok) {
                localStorage.setItem('accessToken', res.accessToken);
                this.$router.push('/admin');
              }
            });
          })
          .catch(response => {
            response.json().then(res => {
              alert(res.message);
            });
          });
      }
    },
    checkInput(ev) {
      if (ev.keyCode === 13) {
        this.login();
      }
    },
    validEmail: function(email) {
      // Perlu di-disable karena kata Vue itu unecessary escape padahal perlu
      // eslint-disable-next-line
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/;
      return re.test(email);
    },
  },
  mounted() {
    document.title = 'Login | Mexico Lotteries';
  },
};
</script>

<style>
#LoginPage {
  background: linear-gradient(white, var(--light));
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

#LoginWindow {
  width: 30%;
  height: 250px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.12),
    0 8px 8px rgba(0, 0, 0, 0.12), 0 16px 16px rgba(0, 0, 0, 0.12);
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

#LoginWindow input {
  width: 100%;
  margin-bottom: 10px;
}

#LoginWindow h1 {
  margin-bottom: 15px;
}
</style>
